/* eslint-disable */
// sequence-metadata v0.4.0 7c434e9c7faba707ea4d030621fb145e47531281
// --
// Code generated by webrpc-gen@v0.18.6 with typescript generator. DO NOT EDIT.
//
// webrpc-gen -schema=metadata.ridl -target=typescript -client -out=./clients/metadata.gen.ts

// WebRPC description and code-gen version
export const WebRPCVersion = 'v1'

// Schema version of your RIDL schema
export const WebRPCSchemaVersion = 'v0.4.0'

// Schema hash generated from your RIDL schema
export const WebRPCSchemaHash = '7c434e9c7faba707ea4d030621fb145e47531281'

//
// Types
//

export enum ContractType {
  UNKNOWN = 'UNKNOWN',
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155'
}

export enum PropertyType {
  INT = 'INT',
  STRING = 'STRING',
  ARRAY = 'ARRAY',
  GENERIC = 'GENERIC'
}

export enum SwapType {
  UNKNOWN = 'UNKNOWN',
  BUY = 'BUY',
  SELL = 'SELL'
}

export enum TaskStatus {
  PENDING = 'PENDING',
  PAUSED = 'PAUSED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  DISABLED = 'DISABLED'
}

export interface Version {
  webrpcVersion: string
  schemaVersion: string
  schemaHash: string
  appVersion: string
}

export interface RuntimeStatus {
  healthOK: boolean
  startTime: string
  uptime: number
  ver: string
  branch: string
  commitHash: string
  checks: RuntimeChecks
}

export interface RuntimeChecks {}

export interface ContractIndex {
  chainId: number
  address: string
  type: ContractType
  metadata: { [key: string]: any }
  contentHash: number
  deployed: boolean
  bytecodeHash: string
  notFound: boolean
  updatedAt: string
}

export interface TokenIndex {
  key: string
  chainId: number
  contractAddress: string
  tokenId: string
  metadata: { [key: string]: any }
  notFound?: boolean
  lastFetched?: string
  fetchCount?: number
  updatedAt: string
}

export interface ContractInfo {
  chainId: number
  address: string
  name: string
  type: string
  symbol: string
  decimals?: number
  logoURI: string
  deployed: boolean
  bytecodeHash: string
  extensions: ContractInfoExtensions
  updatedAt: string
}

export interface ContractInfoExtensions {
  link: string
  description: string
  ogImage: string
  originChainId: number
  originAddress: string
  blacklist: boolean
  verified: boolean
  verifiedBy: string
  featured: boolean
}

export interface TokenMetadata {
  tokenId: string
  name: string
  description?: string
  image?: string
  video?: string
  audio?: string
  properties?: { [key: string]: any }
  attributes: Array<{ [key: string]: any }>
  image_data?: string
  external_url?: string
  background_color?: string
  animation_url?: string
  decimals?: number
  updatedAt?: string
  assets?: Array<Asset>
}

export interface PropertyFilter {
  name: string
  type: PropertyType
  min?: number
  max?: number
  values?: Array<any>
}

export interface Filter {
  text?: string
  properties?: Array<PropertyFilter>
}

export interface Collection {
  id: number
  projectId: number
  metadata: CollectionMetadata
  private: boolean
  revealKey?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  baseURIs?: CollectionBaseURIs
  assets?: Array<Asset>
}

export interface CollectionMetadata {
  name: string
  description?: string
  image?: string
  external_link?: string
  properties?: { [key: string]: any }
  attributes?: Array<{ [key: string]: any }>
}

export interface CollectionBaseURIs {
  contractMetadataURI: string
  tokenMetadataURI: string
}

export interface ContractCollection {
  id: number
  chainId: number
  contractAddress: string
  collectionId: number
}

export interface Asset {
  id: number
  collectionId: number
  tokenId?: string
  url?: string
  metadataField: string
  name?: string
  filesize?: number
  mimeType?: string
  width?: number
  height?: number
  updatedAt?: string
}

export interface Token {
  collectionId: number
  tokenId: string
  metadata: TokenMetadata
  private: boolean
  updatedAt?: string
}

export interface GetNiftyswapUnitPricesRequest {
  swapType: SwapType
  ids: Array<string>
  amounts: Array<string>
}

export interface GetNiftyswapUnitPricesResponse {
  unitPrice: string
  unitAmount: string
  availableAmount: string
}

export interface Page {
  page?: number
  column?: string
  before?: any
  after?: any
  pageSize?: number
  more?: boolean
}

export interface TaskRunner {
  id: number
  workGroup: string
  runAt: string
}

export interface Task {
  id: number
  queue: string
  status?: TaskStatus
  try: number
  runAt?: string
  lastRanAt?: string
  createdAt?: string
  payload: Array<string>
  hash?: string
}

export interface Metadata {
  ping(headers?: object, signal?: AbortSignal): Promise<PingReturn>
  version(headers?: object, signal?: AbortSignal): Promise<VersionReturn>
  runtimeStatus(headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn>
  getTokenMetadata(args: GetTokenMetadataArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenMetadataReturn>
  refreshTokenMetadata(
    args: RefreshTokenMetadataArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RefreshTokenMetadataReturn>
  enqueueTokensForRefresh(
    args: EnqueueTokensForRefreshArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<EnqueueTokensForRefreshReturn>
  getTokenRefreshStatus(
    args: GetTokenRefreshStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenRefreshStatusReturn>
  getTokenRefreshResult(
    args: GetTokenRefreshResultArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenRefreshResultReturn>
  cancelRefreshJob(args: CancelRefreshJobArgs, headers?: object, signal?: AbortSignal): Promise<CancelRefreshJobReturn>
  getTokenMetadataBatch(
    args: GetTokenMetadataBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenMetadataBatchReturn>
  searchTokenMetadata(args: SearchTokenMetadataArgs, headers?: object, signal?: AbortSignal): Promise<SearchTokenMetadataReturn>
  searchTokenIDs(args: SearchTokenIDsArgs, headers?: object, signal?: AbortSignal): Promise<SearchTokenIDsReturn>
  tokenCollectionFilters(
    args: TokenCollectionFiltersArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<TokenCollectionFiltersReturn>
  getContractInfo(args: GetContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<GetContractInfoReturn>
  getContractInfoBatch(
    args: GetContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoBatchReturn>
  searchContractInfo(args: SearchContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<SearchContractInfoReturn>
  searchContractInfoBatch(
    args: SearchContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchContractInfoBatchReturn>
  searchMetadata(args: SearchMetadataArgs, headers?: object, signal?: AbortSignal): Promise<SearchMetadataReturn>
  searchTokens(args: SearchTokensArgs, headers?: object, signal?: AbortSignal): Promise<SearchTokensReturn>
  searchContracts(args: SearchContractsArgs, headers?: object, signal?: AbortSignal): Promise<SearchContractsReturn>
  getNiftyswapTokenQuantity(
    args: GetNiftyswapTokenQuantityArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapTokenQuantityReturn>
  getNiftyswapUnitPrices(
    args: GetNiftyswapUnitPricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesReturn>
  getNiftyswapUnitPricesWithQuantities(
    args: GetNiftyswapUnitPricesWithQuantitiesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesWithQuantitiesReturn>
  addContractToMintMonitor(
    args: AddContractToMintMonitorArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractToMintMonitorReturn>
  removeContractFromMintMonitor(
    args: RemoveContractFromMintMonitorArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractFromMintMonitorReturn>
  mintMonitorJobStatus(
    args: MintMonitorJobStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<MintMonitorJobStatusReturn>
  mintMonitorTriggerJob(
    args: MintMonitorTriggerJobArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<MintMonitorTriggerJobReturn>
  syncContractTokens(args: SyncContractTokensArgs, headers?: object, signal?: AbortSignal): Promise<SyncContractTokensReturn>
  abortContractSync(args: AbortContractSyncArgs, headers?: object, signal?: AbortSignal): Promise<AbortContractSyncReturn>
  contractSyncJobStatus(
    args: ContractSyncJobStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ContractSyncJobStatusReturn>
  directoryGetNetworks(
    args: DirectoryGetNetworksArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DirectoryGetNetworksReturn>
  directoryGetCollections(
    args: DirectoryGetCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DirectoryGetCollectionsReturn>
  directorySearchCollections(
    args: DirectorySearchCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DirectorySearchCollectionsReturn>
}

export interface PingArgs {}

export interface PingReturn {
  status: boolean
}
export interface VersionArgs {}

export interface VersionReturn {
  version: Version
}
export interface RuntimeStatusArgs {}

export interface RuntimeStatusReturn {
  status: RuntimeStatus
}
export interface GetTokenMetadataArgs {
  chainID: string
  contractAddress: string
  tokenIDs: Array<string>
}

export interface GetTokenMetadataReturn {
  tokenMetadata: Array<TokenMetadata>
}
export interface RefreshTokenMetadataArgs {
  chainID: string
  contractAddress: string
  tokenIDs?: Array<string>
  refreshAll?: boolean
}

export interface RefreshTokenMetadataReturn {
  taskId: number
}
export interface EnqueueTokensForRefreshArgs {
  chainID: string
  contractAddress: string
  tokenIDs?: Array<string>
  refreshAll?: boolean
}

export interface EnqueueTokensForRefreshReturn {
  taskId: number
}
export interface GetTokenRefreshStatusArgs {
  taskId: number
}

export interface GetTokenRefreshStatusReturn {
  status?: TaskStatus
}
export interface GetTokenRefreshResultArgs {
  taskId: number
}

export interface GetTokenRefreshResultReturn {
  status?: TaskStatus
  tokens: { [key: string]: boolean }
  failureReasons: { [key: string]: string }
}
export interface CancelRefreshJobArgs {
  taskId: number
}

export interface CancelRefreshJobReturn {
  ok: boolean
}
export interface GetTokenMetadataBatchArgs {
  chainID: string
  contractTokenMap: { [key: string]: Array<string> }
}

export interface GetTokenMetadataBatchReturn {
  contractTokenMetadata: { [key: string]: Array<TokenMetadata> }
}
export interface SearchTokenMetadataArgs {
  chainID: string
  contractAddress: string
  filter: Filter
  page?: Page
}

export interface SearchTokenMetadataReturn {
  page: Page
  tokenMetadata: Array<TokenMetadata>
}
export interface SearchTokenIDsArgs {
  chainID: string
  contractAddress: string
  filter: Filter
  page?: Page
}

export interface SearchTokenIDsReturn {
  page: Page
  tokenIds: Array<string>
}
export interface TokenCollectionFiltersArgs {
  chainID: string
  contractAddress: string
}

export interface TokenCollectionFiltersReturn {
  filters: Array<PropertyFilter>
}
export interface GetContractInfoArgs {
  chainID: string
  contractAddress: string
}

export interface GetContractInfoReturn {
  contractInfo: ContractInfo
}
export interface GetContractInfoBatchArgs {
  chainID: string
  contractAddresses: Array<string>
}

export interface GetContractInfoBatchReturn {
  contractInfoMap: { [key: string]: ContractInfo }
}
export interface SearchContractInfoArgs {
  contractAddress: string
}

export interface SearchContractInfoReturn {
  contractInfoList: Array<ContractInfo>
}
export interface SearchContractInfoBatchArgs {
  contractAddresses: Array<string>
}

export interface SearchContractInfoBatchReturn {
  contractInfoByChain: { [key: string]: Array<ContractInfo> }
}
export interface SearchMetadataArgs {
  filter: string
  chainID?: string
  types?: Array<ContractType>
  excludeTokenMetadata?: boolean
}

export interface SearchMetadataReturn {
  tokenMetadata: Array<TokenMetadata>
  contractInfo: Array<ContractInfo>
}
export interface SearchTokensArgs {
  q: string
  chainID?: string
  page?: Page
}

export interface SearchTokensReturn {
  tokenMetadata: Array<TokenMetadata>
  nextPage: Page
}
export interface SearchContractsArgs {
  q: string
  chainID?: string
  chainIDs?: Array<string>
  types?: Array<ContractType>
  page?: Page
}

export interface SearchContractsReturn {
  contractInfo: Array<ContractInfo>
  nextPage: Page
}
export interface GetNiftyswapTokenQuantityArgs {
  chainID: string
  contractAddress: string
  tokenIDs: Array<string>
}

export interface GetNiftyswapTokenQuantityReturn {
  quantity: { [key: string]: string }
}
export interface GetNiftyswapUnitPricesArgs {
  chainID: string
  contractAddress: string
  req: GetNiftyswapUnitPricesRequest
  fresh: boolean
}

export interface GetNiftyswapUnitPricesReturn {
  prices: { [key: string]: string }
}
export interface GetNiftyswapUnitPricesWithQuantitiesArgs {
  chainID: string
  contractAddress: string
  req: GetNiftyswapUnitPricesRequest
  fresh: boolean
}

export interface GetNiftyswapUnitPricesWithQuantitiesReturn {
  prices: { [key: string]: GetNiftyswapUnitPricesResponse }
}
export interface AddContractToMintMonitorArgs {
  chainID: string
  contractAddress: string
}

export interface AddContractToMintMonitorReturn {
  ok: boolean
}
export interface RemoveContractFromMintMonitorArgs {
  chainID: string
  contractAddress: string
}

export interface RemoveContractFromMintMonitorReturn {
  ok: boolean
}
export interface MintMonitorJobStatusArgs {
  chainID: string
  contractAddress: string
}

export interface MintMonitorJobStatusReturn {
  task: Task
}
export interface MintMonitorTriggerJobArgs {
  chainID: string
  contractAddress: string
}

export interface MintMonitorTriggerJobReturn {
  ok: boolean
}
export interface SyncContractTokensArgs {
  chainID: string
  contractAddress: string
}

export interface SyncContractTokensReturn {
  taskID: number
}
export interface AbortContractSyncArgs {
  taskID: number
}

export interface AbortContractSyncReturn {
  ok: boolean
}
export interface ContractSyncJobStatusArgs {
  taskID: number
}

export interface ContractSyncJobStatusReturn {
  refreshTask: Task
  syncTask: Task
}
export interface DirectoryGetNetworksArgs {
  includeTestnets?: boolean
  onlyFeatured?: boolean
}

export interface DirectoryGetNetworksReturn {
  networks: Array<number>
}
export interface DirectoryGetCollectionsArgs {
  chainId?: number
  includeTestnets?: boolean
  onlyFeatured?: boolean
  page?: Page
}

export interface DirectoryGetCollectionsReturn {
  collections: Array<ContractInfo>
  page: Page
}
export interface DirectorySearchCollectionsArgs {
  query: string
  chainId?: number
  includeTestnets?: boolean
  onlyFeatured?: boolean
  page?: Page
}

export interface DirectorySearchCollectionsReturn {
  collections: Array<ContractInfo>
  page: Page
}

export interface Collections {
  createCollection(args: CreateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<CreateCollectionReturn>
  getCollection(args: GetCollectionArgs, headers?: object, signal?: AbortSignal): Promise<GetCollectionReturn>
  listCollections(args: ListCollectionsArgs, headers?: object, signal?: AbortSignal): Promise<ListCollectionsReturn>
  updateCollection(args: UpdateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<UpdateCollectionReturn>
  deleteCollection(args: DeleteCollectionArgs, headers?: object, signal?: AbortSignal): Promise<DeleteCollectionReturn>
  publishCollection(args: PublishCollectionArgs, headers?: object, signal?: AbortSignal): Promise<PublishCollectionReturn>
  unpublishCollection(args: UnpublishCollectionArgs, headers?: object, signal?: AbortSignal): Promise<UnpublishCollectionReturn>
  createContractCollection(
    args: CreateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<CreateContractCollectionReturn>
  getContractCollection(
    args: GetContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractCollectionReturn>
  listContractCollections(
    args: ListContractCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListContractCollectionsReturn>
  updateContractCollection(
    args: UpdateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateContractCollectionReturn>
  deleteContractCollection(
    args: DeleteContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteContractCollectionReturn>
  createToken(args: CreateTokenArgs, headers?: object, signal?: AbortSignal): Promise<CreateTokenReturn>
  getToken(args: GetTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenReturn>
  listTokens(args: ListTokensArgs, headers?: object, signal?: AbortSignal): Promise<ListTokensReturn>
  updateToken(args: UpdateTokenArgs, headers?: object, signal?: AbortSignal): Promise<UpdateTokenReturn>
  deleteToken(args: DeleteTokenArgs, headers?: object, signal?: AbortSignal): Promise<DeleteTokenReturn>
  createAsset(args: CreateAssetArgs, headers?: object, signal?: AbortSignal): Promise<CreateAssetReturn>
  getAsset(args: GetAssetArgs, headers?: object, signal?: AbortSignal): Promise<GetAssetReturn>
  updateAsset(args: UpdateAssetArgs, headers?: object, signal?: AbortSignal): Promise<UpdateAssetReturn>
  deleteAsset(args: DeleteAssetArgs, headers?: object, signal?: AbortSignal): Promise<DeleteAssetReturn>
}

export interface CreateCollectionArgs {
  projectId?: number
  collection: Collection
}

export interface CreateCollectionReturn {
  collection: Collection
}
export interface GetCollectionArgs {
  projectId?: number
  collectionId: number
}

export interface GetCollectionReturn {
  collection: Collection
}
export interface ListCollectionsArgs {
  projectId?: number
  page?: Page
}

export interface ListCollectionsReturn {
  page: Page
  collections: Array<Collection>
}
export interface UpdateCollectionArgs {
  projectId?: number
  collection: Collection
}

export interface UpdateCollectionReturn {
  collection: Collection
}
export interface DeleteCollectionArgs {
  projectId?: number
  collectionId: number
}

export interface DeleteCollectionReturn {
  status: boolean
}
export interface PublishCollectionArgs {
  projectId?: number
  collectionId: number
  recursive?: boolean
}

export interface PublishCollectionReturn {
  collection: Collection
}
export interface UnpublishCollectionArgs {
  projectId?: number
  collectionId: number
}

export interface UnpublishCollectionReturn {
  collection: Collection
}
export interface CreateContractCollectionArgs {
  projectId: number
  contractCollection: ContractCollection
}

export interface CreateContractCollectionReturn {
  contractCollection: ContractCollection
}
export interface GetContractCollectionArgs {
  projectId: number
  chainId: number
  contractAddress: string
}

export interface GetContractCollectionReturn {
  contractCollection: ContractCollection
}
export interface ListContractCollectionsArgs {
  projectId: number
  collectionId?: number
  page?: Page
}

export interface ListContractCollectionsReturn {
  contractCollections: Array<ContractCollection>
  page: Page
}
export interface UpdateContractCollectionArgs {
  projectId: number
  contractCollection: ContractCollection
}

export interface UpdateContractCollectionReturn {
  ok: boolean
}
export interface DeleteContractCollectionArgs {
  projectId: number
  chainId: number
  contractAddress: string
}

export interface DeleteContractCollectionReturn {
  ok: boolean
}
export interface CreateTokenArgs {
  projectId?: number
  collectionId: number
  token: TokenMetadata
  private?: boolean
}

export interface CreateTokenReturn {
  token: TokenMetadata
  assets: Array<Asset>
}
export interface GetTokenArgs {
  projectId?: number
  collectionId: number
  tokenId: string
}

export interface GetTokenReturn {
  token: TokenMetadata
  assets: Array<Asset>
}
export interface ListTokensArgs {
  projectId?: number
  collectionId: number
  page?: Page
}

export interface ListTokensReturn {
  page: Page
  tokens: Array<TokenMetadata>
}
export interface UpdateTokenArgs {
  projectId?: number
  collectionId: number
  tokenId: string
  token: TokenMetadata
  private?: boolean
}

export interface UpdateTokenReturn {
  token: TokenMetadata
}
export interface DeleteTokenArgs {
  projectId?: number
  collectionId: number
  tokenId: string
}

export interface DeleteTokenReturn {
  status: boolean
}
export interface CreateAssetArgs {
  projectId?: number
  asset: Asset
}

export interface CreateAssetReturn {
  asset: Asset
}
export interface GetAssetArgs {
  projectId?: number
  assetId: number
}

export interface GetAssetReturn {
  asset: Asset
}
export interface UpdateAssetArgs {
  projectId?: number
  asset: Asset
}

export interface UpdateAssetReturn {
  asset: Asset
}
export interface DeleteAssetArgs {
  projectId?: number
  assetId: number
}

export interface DeleteAssetReturn {
  status: boolean
}

export interface Admin {
  addContractsToTokenDirectory(
    args: AddContractsToTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractsToTokenDirectoryReturn>
  removeContractsFromTokenDirectory(
    args: RemoveContractsFromTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractsFromTokenDirectoryReturn>
  modifyFeatureIndex(args: ModifyFeatureIndexArgs, headers?: object, signal?: AbortSignal): Promise<ModifyFeatureIndexReturn>
  getFeatureIndex(args: GetFeatureIndexArgs, headers?: object, signal?: AbortSignal): Promise<GetFeatureIndexReturn>
  listTokenDirectory(args: ListTokenDirectoryArgs, headers?: object, signal?: AbortSignal): Promise<ListTokenDirectoryReturn>
}

export interface AddContractsToTokenDirectoryArgs {
  contracts: Array<ContractInfo>
  featureIndexes: Array<number>
}

export interface AddContractsToTokenDirectoryReturn {
  ok: boolean
}
export interface RemoveContractsFromTokenDirectoryArgs {
  chainHandle: string
  contracts: Array<string>
}

export interface RemoveContractsFromTokenDirectoryReturn {
  ok: boolean
}
export interface ModifyFeatureIndexArgs {
  chainHandle: string
  contractAddress: string
  featured: number
}

export interface ModifyFeatureIndexReturn {
  ok: boolean
}
export interface GetFeatureIndexArgs {
  chainHandle: string
  contractAddress: string
}

export interface GetFeatureIndexReturn {
  featured: number
}
export interface ListTokenDirectoryArgs {
  chainID?: number
  includeTestnets?: boolean
  onlyFeatured?: boolean
  page?: Page
}

export interface ListTokenDirectoryReturn {
  page: Page
  collections: Array<ContractInfo>
}

//
// Client
//
export class Metadata implements Metadata {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Metadata/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  ping = (headers?: object, signal?: AbortSignal): Promise<PingReturn> => {
    return this.fetch(this.url('Ping'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  version = (headers?: object, signal?: AbortSignal): Promise<VersionReturn> => {
    return this.fetch(this.url('Version'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            version: <Version>_data.version
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  runtimeStatus = (headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn> => {
    return this.fetch(this.url('RuntimeStatus'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <RuntimeStatus>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenMetadata = (args: GetTokenMetadataArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenMetadataReturn> => {
    return this.fetch(this.url('GetTokenMetadata'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenMetadata: <Array<TokenMetadata>>_data.tokenMetadata
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  refreshTokenMetadata = (
    args: RefreshTokenMetadataArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RefreshTokenMetadataReturn> => {
    return this.fetch(this.url('RefreshTokenMetadata'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            taskId: <number>_data.taskId
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  enqueueTokensForRefresh = (
    args: EnqueueTokensForRefreshArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<EnqueueTokensForRefreshReturn> => {
    return this.fetch(this.url('EnqueueTokensForRefresh'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            taskId: <number>_data.taskId
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenRefreshStatus = (
    args: GetTokenRefreshStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenRefreshStatusReturn> => {
    return this.fetch(this.url('GetTokenRefreshStatus'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <TaskStatus>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenRefreshResult = (
    args: GetTokenRefreshResultArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenRefreshResultReturn> => {
    return this.fetch(this.url('GetTokenRefreshResult'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <TaskStatus>_data.status,
            tokens: <{ [key: string]: boolean }>_data.tokens,
            failureReasons: <{ [key: string]: string }>_data.failureReasons
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  cancelRefreshJob = (args: CancelRefreshJobArgs, headers?: object, signal?: AbortSignal): Promise<CancelRefreshJobReturn> => {
    return this.fetch(this.url('CancelRefreshJob'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenMetadataBatch = (
    args: GetTokenMetadataBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenMetadataBatchReturn> => {
    return this.fetch(this.url('GetTokenMetadataBatch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractTokenMetadata: <{ [key: string]: Array<TokenMetadata> }>_data.contractTokenMetadata
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchTokenMetadata = (
    args: SearchTokenMetadataArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenMetadataReturn> => {
    return this.fetch(this.url('SearchTokenMetadata'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            tokenMetadata: <Array<TokenMetadata>>_data.tokenMetadata
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchTokenIDs = (args: SearchTokenIDsArgs, headers?: object, signal?: AbortSignal): Promise<SearchTokenIDsReturn> => {
    return this.fetch(this.url('SearchTokenIDs'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            tokenIds: <Array<string>>_data.tokenIds
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  tokenCollectionFilters = (
    args: TokenCollectionFiltersArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<TokenCollectionFiltersReturn> => {
    return this.fetch(this.url('TokenCollectionFilters'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            filters: <Array<PropertyFilter>>_data.filters
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getContractInfo = (args: GetContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<GetContractInfoReturn> => {
    return this.fetch(this.url('GetContractInfo'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfo: <ContractInfo>_data.contractInfo
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getContractInfoBatch = (
    args: GetContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoBatchReturn> => {
    return this.fetch(this.url('GetContractInfoBatch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoMap: <{ [key: string]: ContractInfo }>_data.contractInfoMap
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchContractInfo = (
    args: SearchContractInfoArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchContractInfoReturn> => {
    return this.fetch(this.url('SearchContractInfo'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoList: <Array<ContractInfo>>_data.contractInfoList
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchContractInfoBatch = (
    args: SearchContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchContractInfoBatchReturn> => {
    return this.fetch(this.url('SearchContractInfoBatch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoByChain: <{ [key: string]: Array<ContractInfo> }>_data.contractInfoByChain
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchMetadata = (args: SearchMetadataArgs, headers?: object, signal?: AbortSignal): Promise<SearchMetadataReturn> => {
    return this.fetch(this.url('SearchMetadata'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenMetadata: <Array<TokenMetadata>>_data.tokenMetadata,
            contractInfo: <Array<ContractInfo>>_data.contractInfo
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchTokens = (args: SearchTokensArgs, headers?: object, signal?: AbortSignal): Promise<SearchTokensReturn> => {
    return this.fetch(this.url('SearchTokens'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenMetadata: <Array<TokenMetadata>>_data.tokenMetadata,
            nextPage: <Page>_data.nextPage
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchContracts = (args: SearchContractsArgs, headers?: object, signal?: AbortSignal): Promise<SearchContractsReturn> => {
    return this.fetch(this.url('SearchContracts'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfo: <Array<ContractInfo>>_data.contractInfo,
            nextPage: <Page>_data.nextPage
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getNiftyswapTokenQuantity = (
    args: GetNiftyswapTokenQuantityArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapTokenQuantityReturn> => {
    return this.fetch(this.url('GetNiftyswapTokenQuantity'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            quantity: <{ [key: string]: string }>_data.quantity
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getNiftyswapUnitPrices = (
    args: GetNiftyswapUnitPricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesReturn> => {
    return this.fetch(this.url('GetNiftyswapUnitPrices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            prices: <{ [key: string]: string }>_data.prices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getNiftyswapUnitPricesWithQuantities = (
    args: GetNiftyswapUnitPricesWithQuantitiesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesWithQuantitiesReturn> => {
    return this.fetch(this.url('GetNiftyswapUnitPricesWithQuantities'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            prices: <{ [key: string]: GetNiftyswapUnitPricesResponse }>_data.prices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addContractToMintMonitor = (
    args: AddContractToMintMonitorArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractToMintMonitorReturn> => {
    return this.fetch(this.url('AddContractToMintMonitor'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeContractFromMintMonitor = (
    args: RemoveContractFromMintMonitorArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractFromMintMonitorReturn> => {
    return this.fetch(this.url('RemoveContractFromMintMonitor'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  mintMonitorJobStatus = (
    args: MintMonitorJobStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<MintMonitorJobStatusReturn> => {
    return this.fetch(this.url('MintMonitorJobStatus'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            task: <Task>_data.task
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  mintMonitorTriggerJob = (
    args: MintMonitorTriggerJobArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<MintMonitorTriggerJobReturn> => {
    return this.fetch(this.url('MintMonitorTriggerJob'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  syncContractTokens = (
    args: SyncContractTokensArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SyncContractTokensReturn> => {
    return this.fetch(this.url('SyncContractTokens'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  abortContractSync = (args: AbortContractSyncArgs, headers?: object, signal?: AbortSignal): Promise<AbortContractSyncReturn> => {
    return this.fetch(this.url('AbortContractSync'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  contractSyncJobStatus = (
    args: ContractSyncJobStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ContractSyncJobStatusReturn> => {
    return this.fetch(this.url('ContractSyncJobStatus'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            refreshTask: <Task>_data.refreshTask,
            syncTask: <Task>_data.syncTask
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  directoryGetNetworks = (
    args: DirectoryGetNetworksArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DirectoryGetNetworksReturn> => {
    return this.fetch(this.url('DirectoryGetNetworks'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            networks: <Array<number>>_data.networks
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  directoryGetCollections = (
    args: DirectoryGetCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DirectoryGetCollectionsReturn> => {
    return this.fetch(this.url('DirectoryGetCollections'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collections: <Array<ContractInfo>>_data.collections,
            page: <Page>_data.page
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  directorySearchCollections = (
    args: DirectorySearchCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DirectorySearchCollectionsReturn> => {
    return this.fetch(this.url('DirectorySearchCollections'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collections: <Array<ContractInfo>>_data.collections,
            page: <Page>_data.page
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}
export class Collections implements Collections {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Collections/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  createCollection = (args: CreateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<CreateCollectionReturn> => {
    return this.fetch(this.url('CreateCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getCollection = (args: GetCollectionArgs, headers?: object, signal?: AbortSignal): Promise<GetCollectionReturn> => {
    return this.fetch(this.url('GetCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listCollections = (args: ListCollectionsArgs, headers?: object, signal?: AbortSignal): Promise<ListCollectionsReturn> => {
    return this.fetch(this.url('ListCollections'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            collections: <Array<Collection>>_data.collections
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateCollection = (args: UpdateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<UpdateCollectionReturn> => {
    return this.fetch(this.url('UpdateCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteCollection = (args: DeleteCollectionArgs, headers?: object, signal?: AbortSignal): Promise<DeleteCollectionReturn> => {
    return this.fetch(this.url('DeleteCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  publishCollection = (args: PublishCollectionArgs, headers?: object, signal?: AbortSignal): Promise<PublishCollectionReturn> => {
    return this.fetch(this.url('PublishCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  unpublishCollection = (
    args: UnpublishCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UnpublishCollectionReturn> => {
    return this.fetch(this.url('UnpublishCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  createContractCollection = (
    args: CreateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<CreateContractCollectionReturn> => {
    return this.fetch(this.url('CreateContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractCollection: <ContractCollection>_data.contractCollection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getContractCollection = (
    args: GetContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractCollectionReturn> => {
    return this.fetch(this.url('GetContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractCollection: <ContractCollection>_data.contractCollection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listContractCollections = (
    args: ListContractCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListContractCollectionsReturn> => {
    return this.fetch(this.url('ListContractCollections'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractCollections: <Array<ContractCollection>>_data.contractCollections,
            page: <Page>_data.page
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateContractCollection = (
    args: UpdateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateContractCollectionReturn> => {
    return this.fetch(this.url('UpdateContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteContractCollection = (
    args: DeleteContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteContractCollectionReturn> => {
    return this.fetch(this.url('DeleteContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  createToken = (args: CreateTokenArgs, headers?: object, signal?: AbortSignal): Promise<CreateTokenReturn> => {
    return this.fetch(this.url('CreateToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <TokenMetadata>_data.token,
            assets: <Array<Asset>>_data.assets
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getToken = (args: GetTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenReturn> => {
    return this.fetch(this.url('GetToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <TokenMetadata>_data.token,
            assets: <Array<Asset>>_data.assets
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listTokens = (args: ListTokensArgs, headers?: object, signal?: AbortSignal): Promise<ListTokensReturn> => {
    return this.fetch(this.url('ListTokens'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            tokens: <Array<TokenMetadata>>_data.tokens
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateToken = (args: UpdateTokenArgs, headers?: object, signal?: AbortSignal): Promise<UpdateTokenReturn> => {
    return this.fetch(this.url('UpdateToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <TokenMetadata>_data.token
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteToken = (args: DeleteTokenArgs, headers?: object, signal?: AbortSignal): Promise<DeleteTokenReturn> => {
    return this.fetch(this.url('DeleteToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  createAsset = (args: CreateAssetArgs, headers?: object, signal?: AbortSignal): Promise<CreateAssetReturn> => {
    return this.fetch(this.url('CreateAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            asset: <Asset>_data.asset
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getAsset = (args: GetAssetArgs, headers?: object, signal?: AbortSignal): Promise<GetAssetReturn> => {
    return this.fetch(this.url('GetAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            asset: <Asset>_data.asset
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateAsset = (args: UpdateAssetArgs, headers?: object, signal?: AbortSignal): Promise<UpdateAssetReturn> => {
    return this.fetch(this.url('UpdateAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            asset: <Asset>_data.asset
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteAsset = (args: DeleteAssetArgs, headers?: object, signal?: AbortSignal): Promise<DeleteAssetReturn> => {
    return this.fetch(this.url('DeleteAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}
export class Admin implements Admin {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Admin/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  addContractsToTokenDirectory = (
    args: AddContractsToTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractsToTokenDirectoryReturn> => {
    return this.fetch(this.url('AddContractsToTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeContractsFromTokenDirectory = (
    args: RemoveContractsFromTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractsFromTokenDirectoryReturn> => {
    return this.fetch(this.url('RemoveContractsFromTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  modifyFeatureIndex = (
    args: ModifyFeatureIndexArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ModifyFeatureIndexReturn> => {
    return this.fetch(this.url('ModifyFeatureIndex'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getFeatureIndex = (args: GetFeatureIndexArgs, headers?: object, signal?: AbortSignal): Promise<GetFeatureIndexReturn> => {
    return this.fetch(this.url('GetFeatureIndex'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            featured: <number>_data.featured
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listTokenDirectory = (
    args: ListTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListTokenDirectoryReturn> => {
    return this.fetch(this.url('ListTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            collections: <Array<ContractInfo>>_data.collections
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}

const createHTTPRequest = (body: object = {}, headers: object = {}, signal: AbortSignal | null = null): object => {
  return {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(body || {}),
    signal
  }
}

const buildResponse = (res: Response): Promise<any> => {
  return res.text().then(text => {
    let data
    try {
      data = JSON.parse(text)
    } catch (error) {
      let message = ''
      if (error instanceof Error) {
        message = error.message
      }
      throw WebrpcBadResponseError.new({
        status: res.status,
        cause: `JSON.parse(): ${message}: response text: ${text}`
      })
    }
    if (!res.ok) {
      const code: number = typeof data.code === 'number' ? data.code : 0
      throw (webrpcErrorByCode[code] || WebrpcError).new(data)
    }
    return data
  })
}

//
// Errors
//

export class WebrpcError extends Error {
  name: string
  code: number
  message: string
  status: number
  cause?: string

  /** @deprecated Use message instead of msg. Deprecated in webrpc v0.11.0. */
  msg: string

  constructor(name: string, code: number, message: string, status: number, cause?: string) {
    super(message)
    this.name = name || 'WebrpcError'
    this.code = typeof code === 'number' ? code : 0
    this.message = message || `endpoint error ${this.code}`
    this.msg = this.message
    this.status = typeof status === 'number' ? status : 0
    this.cause = cause
    Object.setPrototypeOf(this, WebrpcError.prototype)
  }

  static new(payload: any): WebrpcError {
    return new this(payload.error, payload.code, payload.message || payload.msg, payload.status, payload.cause)
  }
}

// Webrpc errors

export class WebrpcEndpointError extends WebrpcError {
  constructor(
    name: string = 'WebrpcEndpoint',
    code: number = 0,
    message: string = 'endpoint error',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcEndpointError.prototype)
  }
}

export class WebrpcRequestFailedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcRequestFailed',
    code: number = -1,
    message: string = 'request failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcRequestFailedError.prototype)
  }
}

export class WebrpcBadRouteError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRoute',
    code: number = -2,
    message: string = 'bad route',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRouteError.prototype)
  }
}

export class WebrpcBadMethodError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadMethod',
    code: number = -3,
    message: string = 'bad method',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadMethodError.prototype)
  }
}

export class WebrpcBadRequestError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRequest',
    code: number = -4,
    message: string = 'bad request',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRequestError.prototype)
  }
}

export class WebrpcBadResponseError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadResponse',
    code: number = -5,
    message: string = 'bad response',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadResponseError.prototype)
  }
}

export class WebrpcServerPanicError extends WebrpcError {
  constructor(
    name: string = 'WebrpcServerPanic',
    code: number = -6,
    message: string = 'server panic',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcServerPanicError.prototype)
  }
}

export class WebrpcInternalErrorError extends WebrpcError {
  constructor(
    name: string = 'WebrpcInternalError',
    code: number = -7,
    message: string = 'internal error',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcInternalErrorError.prototype)
  }
}

export class WebrpcClientDisconnectedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcClientDisconnected',
    code: number = -8,
    message: string = 'client disconnected',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcClientDisconnectedError.prototype)
  }
}

export class WebrpcStreamLostError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamLost',
    code: number = -9,
    message: string = 'stream lost',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamLostError.prototype)
  }
}

export class WebrpcStreamFinishedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamFinished',
    code: number = -10,
    message: string = 'stream finished',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamFinishedError.prototype)
  }
}

// Schema errors

export class UnauthorizedError extends WebrpcError {
  constructor(
    name: string = 'Unauthorized',
    code: number = 1000,
    message: string = 'Unauthorized access',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnauthorizedError.prototype)
  }
}

export class PermissionDeniedError extends WebrpcError {
  constructor(
    name: string = 'PermissionDenied',
    code: number = 1001,
    message: string = 'Permission denied',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, PermissionDeniedError.prototype)
  }
}

export class SessionExpiredError extends WebrpcError {
  constructor(
    name: string = 'SessionExpired',
    code: number = 1002,
    message: string = 'Session expired',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, SessionExpiredError.prototype)
  }
}

export class MethodNotFoundError extends WebrpcError {
  constructor(
    name: string = 'MethodNotFound',
    code: number = 1003,
    message: string = 'Method not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, MethodNotFoundError.prototype)
  }
}

export class RequestConflictError extends WebrpcError {
  constructor(
    name: string = 'RequestConflict',
    code: number = 1004,
    message: string = 'Conflict with target resource',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RequestConflictError.prototype)
  }
}

export class FailError extends WebrpcError {
  constructor(
    name: string = 'Fail',
    code: number = 1005,
    message: string = 'Request Failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, FailError.prototype)
  }
}

export class GeoblockedError extends WebrpcError {
  constructor(
    name: string = 'Geoblocked',
    code: number = 1006,
    message: string = 'Geoblocked region',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, GeoblockedError.prototype)
  }
}

export class TimeoutError extends WebrpcError {
  constructor(
    name: string = 'Timeout',
    code: number = 2000,
    message: string = 'Request timed out',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, TimeoutError.prototype)
  }
}

export class InvalidArgumentError extends WebrpcError {
  constructor(
    name: string = 'InvalidArgument',
    code: number = 2001,
    message: string = 'Invalid argument',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidArgumentError.prototype)
  }
}

export class RequiredArgumentError extends WebrpcError {
  constructor(
    name: string = 'RequiredArgument',
    code: number = 2002,
    message: string = 'Required argument missing',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RequiredArgumentError.prototype)
  }
}

export class QueryFailedError extends WebrpcError {
  constructor(
    name: string = 'QueryFailed',
    code: number = 2003,
    message: string = 'Query failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QueryFailedError.prototype)
  }
}

export class ValidationFailedError extends WebrpcError {
  constructor(
    name: string = 'ValidationFailed',
    code: number = 2004,
    message: string = 'Validation failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ValidationFailedError.prototype)
  }
}

export class RateLimitedError extends WebrpcError {
  constructor(
    name: string = 'RateLimited',
    code: number = 2005,
    message: string = 'Rate limited',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RateLimitedError.prototype)
  }
}

export class NotFoundError extends WebrpcError {
  constructor(
    name: string = 'NotFound',
    code: number = 3000,
    message: string = 'Resource not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}

export class ProjectNotFoundError extends WebrpcError {
  constructor(
    name: string = 'ProjectNotFound',
    code: number = 3002,
    message: string = 'Project not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ProjectNotFoundError.prototype)
  }
}

export class ChainNotFoundError extends WebrpcError {
  constructor(
    name: string = 'ChainNotFound',
    code: number = 3003,
    message: string = 'Chain not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ChainNotFoundError.prototype)
  }
}

export class TokenDirectoryDisabledError extends WebrpcError {
  constructor(
    name: string = 'TokenDirectoryDisabled',
    code: number = 4001,
    message: string = 'Token Directory is disabled',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, TokenDirectoryDisabledError.prototype)
  }
}

export enum errors {
  WebrpcEndpoint = 'WebrpcEndpoint',
  WebrpcRequestFailed = 'WebrpcRequestFailed',
  WebrpcBadRoute = 'WebrpcBadRoute',
  WebrpcBadMethod = 'WebrpcBadMethod',
  WebrpcBadRequest = 'WebrpcBadRequest',
  WebrpcBadResponse = 'WebrpcBadResponse',
  WebrpcServerPanic = 'WebrpcServerPanic',
  WebrpcInternalError = 'WebrpcInternalError',
  WebrpcClientDisconnected = 'WebrpcClientDisconnected',
  WebrpcStreamLost = 'WebrpcStreamLost',
  WebrpcStreamFinished = 'WebrpcStreamFinished',
  Unauthorized = 'Unauthorized',
  PermissionDenied = 'PermissionDenied',
  SessionExpired = 'SessionExpired',
  MethodNotFound = 'MethodNotFound',
  RequestConflict = 'RequestConflict',
  Fail = 'Fail',
  Geoblocked = 'Geoblocked',
  Timeout = 'Timeout',
  InvalidArgument = 'InvalidArgument',
  RequiredArgument = 'RequiredArgument',
  QueryFailed = 'QueryFailed',
  ValidationFailed = 'ValidationFailed',
  RateLimited = 'RateLimited',
  NotFound = 'NotFound',
  ProjectNotFound = 'ProjectNotFound',
  ChainNotFound = 'ChainNotFound',
  TokenDirectoryDisabled = 'TokenDirectoryDisabled'
}

const webrpcErrorByCode: { [code: number]: any } = {
  [0]: WebrpcEndpointError,
  [-1]: WebrpcRequestFailedError,
  [-2]: WebrpcBadRouteError,
  [-3]: WebrpcBadMethodError,
  [-4]: WebrpcBadRequestError,
  [-5]: WebrpcBadResponseError,
  [-6]: WebrpcServerPanicError,
  [-7]: WebrpcInternalErrorError,
  [-8]: WebrpcClientDisconnectedError,
  [-9]: WebrpcStreamLostError,
  [-10]: WebrpcStreamFinishedError,
  [1000]: UnauthorizedError,
  [1001]: PermissionDeniedError,
  [1002]: SessionExpiredError,
  [1003]: MethodNotFoundError,
  [1004]: RequestConflictError,
  [1005]: FailError,
  [1006]: GeoblockedError,
  [2000]: TimeoutError,
  [2001]: InvalidArgumentError,
  [2002]: RequiredArgumentError,
  [2003]: QueryFailedError,
  [2004]: ValidationFailedError,
  [2005]: RateLimitedError,
  [3000]: NotFoundError,
  [3002]: ProjectNotFoundError,
  [3003]: ChainNotFoundError,
  [4001]: TokenDirectoryDisabledError
}

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>
