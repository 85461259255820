/* eslint-disable */
// sequence-api v0.4.0 d3f5f1338693d60d58f87bc408a076218201a097
// --
// Code generated by webrpc-gen@v0.18.7 with typescript generator. DO NOT EDIT.
//
// webrpc-gen -schema=api.ridl -target=typescript -client -out=./clients/api.gen.ts

// WebRPC description and code-gen version
export const WebRPCVersion = 'v1'

// Schema version of your RIDL schema
export const WebRPCSchemaVersion = 'v0.4.0'

// Schema hash generated from your RIDL schema
export const WebRPCSchemaHash = 'd3f5f1338693d60d58f87bc408a076218201a097'

//
// Types
//

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC'
}

export enum TokenType {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155'
}

export interface Version {
  webrpcVersion: string
  schemaVersion: string
  schemaHash: string
  appVersion: string
}

export interface RuntimeStatus {
  healthOK: boolean
  startTime: string
  uptime: number
  ver: string
  branch: string
  commitHash: string
  checks: RuntimeChecks
  numTxnsRelayed: { [key: string]: NumTxnsRelayed }
}

export interface NumTxnsRelayed {
  chainID: number
  prev: number
  current: number
  period: number
}

export interface RuntimeChecks {}

export interface SequenceContext {
  factory: string
  mainModule: string
  mainModuleUpgradable: string
  guestModule: string
  utils: string
}

export interface User {
  address: string
  username: string
  avatar: string
  bio: string
  location: string
  locale: string
  backup?: boolean
  backupConfirmed?: boolean
  maxInvites?: number
  updatedAt?: string
  createdAt?: string
}

export interface WalletBackup {
  accountAddress: string
  secretHash: string
  encryptedWallet: string
  userConfirmed: boolean
  updatedAt?: string
  createdAt?: string
}

export interface Friend {
  id: number
  userAddress: string
  friendAddress: string
  nickname: string
  user?: User
  createdAt?: string
}

export interface InviteCode {
  usesLeft: number
  ownerAccount: string
  email?: string
  url: string
  createdAt?: string
  expiresAt?: string
}

export interface InviteCodeAccount {
  claimedByUserAddress: string
  claimedAt?: string
}

export interface InviteInfo {
  expiryInHours: number
  max: number
  invites: Array<InviteCode>
}

export interface ContractCall {
  signature: string
  function: string
  args: Array<TupleComponent>
}

export interface TupleComponent {
  name?: string
  type: string
  value: any
}

export interface Transaction {
  delegateCall: boolean
  revertOnError: boolean
  gasLimit: string
  target: string
  value: string
  data: string
  call?: ContractCall
}

export interface UserStorage {
  userAddress: string
  key: string
  value: any
}

export interface Token {
  chainId: number
  contractAddress: string
  tokenId?: string
}

export interface Price {
  value: number
  currency: string
}

export interface TokenPrice {
  token: Token
  price?: Price
  price24hChange?: Price
  floorPrice: Price
  buyPrice: Price
  sellPrice: Price
  updatedAt: string
}

export interface ExchangeRate {
  name: string
  symbol: string
  value: number
  vsCurrency: string
  currencyType: string
}

export interface LinkedWallet {
  id: number
  walletAddress: string
  linkedWalletAddress: string
  createdAt?: string
}

export interface Page {
  pageSize?: number
  page?: number
  totalRecords?: number
  column?: string
  before?: any
  after?: any
  sort?: Array<SortBy>
  more?: boolean
}

export interface SortBy {
  column: string
  order: SortOrder
}

export interface NftCheckoutParams {
  name: string
  imageUrl: string
  network: string
  recipientAddress: string
  blockchainNftId: string
  contractAddress: string
  quantity: number
  decimals?: number
  tokenAmount: string
  tokenAddress: string
  tokenSymbol: string
  tokenDecimals?: number
  calldata: string
  platform: string
  approvedSpenderAddress?: string
}

export interface NftCheckout {
  token: string
  expiresAt: string
  orderId: string
}

export interface SardineOrder {
  id: string
  createdAt?: string
  referenceId: string
  status: string
  fiatCurrency: string
  fiatExchangeRateUSD: number
  transactionId: string
  expiresAt?: string
  total: number
  subTotal: number
  transactionFee: number
  networkFee: number
  paymentCurrency?: string
  paymentMethodType?: string
  transactionType: string
  name: string
  price: number
  imageUrl: string
  contractAddress?: string
  transactionHash?: string
  recipientAddress: string
}

export interface SwapQuote {
  currencyAddress: string
  currencyBalance: string
  price: string
  maxPrice: string
  to: string
  transactionData: string
  approveData: string
}

export interface CurrencyGroup {
  id: number
  name: string
  tokens: Array<CurrencyGroupToken>
}

export interface CurrencyGroupToken {
  id: number
  currencyGroupId: number
  chainId: number
  tokenAddress: string
}

export interface InventoryPaymentConfig {
  id: number
  projectId: number
  chainId: number
  externalProductId: string
  paymentTokenAddress: string
  paymentTokenType: TokenType
  paymentTokenId: number
  paymentAmount: number
  paymentRecipient: string
  chainedCallAddress?: string
  chainedCallData?: string
  allowCrossChainPayments?: boolean
  callbackURL?: string
  createdAt: string
  deletedAt?: string
}

export interface InventoryPayment {
  id: number
  inventoryPaymentConfigId: number
  productRecipient: string
  paymentChainId: number
  paymentTokenAddress: string
  expiration: string
  createdAt: string
  completedAt?: string
  processedAt?: string
}

export interface InventoryPaymentResponse {
  paymentId: number
  inventoryPaymentConfigId: number
  chainId: number
  externalProductId: string
  paymentTokenAddress: string
  paymentTokenType: TokenType
  paymentTokenId: number
  paymentTotal: number
  expiration: string
  signature: string
  txTo: string
  txData: string
}

export interface API {
  ping(headers?: object, signal?: AbortSignal): Promise<PingReturn>
  version(headers?: object, signal?: AbortSignal): Promise<VersionReturn>
  runtimeStatus(headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn>
  clock(headers?: object, signal?: AbortSignal): Promise<ClockReturn>
  getSequenceContext(headers?: object, signal?: AbortSignal): Promise<GetSequenceContextReturn>
  getAuthToken(args: GetAuthTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetAuthTokenReturn>
  getAuthToken2(args: GetAuthToken2Args, headers?: object, signal?: AbortSignal): Promise<GetAuthToken2Return>
  sendPasswordlessLink(
    args: SendPasswordlessLinkArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SendPasswordlessLinkReturn>
  friendList(args: FriendListArgs, headers?: object, signal?: AbortSignal): Promise<FriendListReturn>
  getFriendByAddress(args: GetFriendByAddressArgs, headers?: object, signal?: AbortSignal): Promise<GetFriendByAddressReturn>
  searchFriends(args: SearchFriendsArgs, headers?: object, signal?: AbortSignal): Promise<SearchFriendsReturn>
  addFriend(args: AddFriendArgs, headers?: object, signal?: AbortSignal): Promise<AddFriendReturn>
  updateFriendNickname(
    args: UpdateFriendNicknameArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateFriendNicknameReturn>
  removeFriend(args: RemoveFriendArgs, headers?: object, signal?: AbortSignal): Promise<RemoveFriendReturn>
  contractCall(args: ContractCallArgs, headers?: object, signal?: AbortSignal): Promise<ContractCallReturn>
  decodeContractCall(args: DecodeContractCallArgs, headers?: object, signal?: AbortSignal): Promise<DecodeContractCallReturn>
  lookupContractCallSelectors(
    args: LookupContractCallSelectorsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<LookupContractCallSelectorsReturn>
  userStorageFetch(args: UserStorageFetchArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageFetchReturn>
  userStorageSave(args: UserStorageSaveArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageSaveReturn>
  userStorageDelete(args: UserStorageDeleteArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageDeleteReturn>
  userStorageFetchAll(args: UserStorageFetchAllArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageFetchAllReturn>
  getMoonpayLink(args: GetMoonpayLinkArgs, headers?: object, signal?: AbortSignal): Promise<GetMoonpayLinkReturn>
  getSardineClientToken(headers?: object, signal?: AbortSignal): Promise<GetSardineClientTokenReturn>
  getSardineNFTCheckoutToken(
    args: GetSardineNFTCheckoutTokenArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutTokenReturn>
  getSardineNFTCheckoutOrderStatus(
    args: GetSardineNFTCheckoutOrderStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutOrderStatusReturn>
  resolveENSAddress(args: ResolveENSAddressArgs, headers?: object, signal?: AbortSignal): Promise<ResolveENSAddressReturn>
  isValidSignature(args: IsValidSignatureArgs, headers?: object, signal?: AbortSignal): Promise<IsValidSignatureReturn>
  isValidMessageSignature(
    args: IsValidMessageSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidMessageSignatureReturn>
  isValidTypedDataSignature(
    args: IsValidTypedDataSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidTypedDataSignatureReturn>
  isValidETHAuthProof(args: IsValidETHAuthProofArgs, headers?: object, signal?: AbortSignal): Promise<IsValidETHAuthProofReturn>
  getCoinPrices(args: GetCoinPricesArgs, headers?: object, signal?: AbortSignal): Promise<GetCoinPricesReturn>
  getCollectiblePrices(
    args: GetCollectiblePricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetCollectiblePricesReturn>
  getExchangeRate(args: GetExchangeRateArgs, headers?: object, signal?: AbortSignal): Promise<GetExchangeRateReturn>
  memoryStore(args: MemoryStoreArgs, headers?: object, signal?: AbortSignal): Promise<MemoryStoreReturn>
  memoryLoad(args: MemoryLoadArgs, headers?: object, signal?: AbortSignal): Promise<MemoryLoadReturn>
  getInviteInfo(headers?: object, signal?: AbortSignal): Promise<GetInviteInfoReturn>
  isValidAccessCode(args: IsValidAccessCodeArgs, headers?: object, signal?: AbortSignal): Promise<IsValidAccessCodeReturn>
  internalClaimAccessCode(
    args: InternalClaimAccessCodeArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<InternalClaimAccessCodeReturn>
  blockNumberAtTime(args: BlockNumberAtTimeArgs, headers?: object, signal?: AbortSignal): Promise<BlockNumberAtTimeReturn>
  paperSessionSecret(args: PaperSessionSecretArgs, headers?: object, signal?: AbortSignal): Promise<PaperSessionSecretReturn>
  paperSessionSecret2(args: PaperSessionSecret2Args, headers?: object, signal?: AbortSignal): Promise<PaperSessionSecret2Return>
  linkWallet(args: LinkWalletArgs, headers?: object, signal?: AbortSignal): Promise<LinkWalletReturn>
  getLinkedWallets(args: GetLinkedWalletsArgs, headers?: object, signal?: AbortSignal): Promise<GetLinkedWalletsReturn>
  removeLinkedWallet(args: RemoveLinkedWalletArgs, headers?: object, signal?: AbortSignal): Promise<RemoveLinkedWalletReturn>
  generateWaaSVerificationURL(
    args: GenerateWaaSVerificationURLArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GenerateWaaSVerificationURLReturn>
  validateWaaSVerificationNonce(
    args: ValidateWaaSVerificationNonceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ValidateWaaSVerificationNonceReturn>
  getSwapQuotes(args: GetSwapQuotesArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapQuotesReturn>
  addCurrencyGroup(args: AddCurrencyGroupArgs, headers?: object, signal?: AbortSignal): Promise<AddCurrencyGroupReturn>
  updateCurrencyGroup(args: UpdateCurrencyGroupArgs, headers?: object, signal?: AbortSignal): Promise<UpdateCurrencyGroupReturn>
  listCurrencyGroups(headers?: object, signal?: AbortSignal): Promise<ListCurrencyGroupsReturn>
  deleteCurrencyGroup(args: DeleteCurrencyGroupArgs, headers?: object, signal?: AbortSignal): Promise<DeleteCurrencyGroupReturn>
  addInventoryPaymentConfig(
    args: AddInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddInventoryPaymentConfigReturn>
  getInventoryPaymentConfig(
    args: GetInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetInventoryPaymentConfigReturn>
  listInventoryPaymentConfigs(
    args: ListInventoryPaymentConfigsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListInventoryPaymentConfigsReturn>
  updateInventoryPaymentConfig(
    args: UpdateInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateInventoryPaymentConfigReturn>
  deleteInventoryPaymentConfig(
    args: DeleteInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteInventoryPaymentConfigReturn>
  requestInventoryPayment(
    args: RequestInventoryPaymentArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RequestInventoryPaymentReturn>
}

export interface PingArgs {}

export interface PingReturn {
  status: boolean
}
export interface VersionArgs {}

export interface VersionReturn {
  version: Version
}
export interface RuntimeStatusArgs {}

export interface RuntimeStatusReturn {
  status: RuntimeStatus
}
export interface ClockArgs {}

export interface ClockReturn {
  serverTime: string
}
export interface GetSequenceContextArgs {}

export interface GetSequenceContextReturn {
  data: SequenceContext
}
export interface GetAuthTokenArgs {
  ewtString: string
  testnetMode?: boolean
}

export interface GetAuthTokenReturn {
  status: boolean
  jwtToken: string
  address: string
  user?: User
}
export interface GetAuthToken2Args {
  ewtString: string
  chainID: string
}

export interface GetAuthToken2Return {
  status: boolean
  jwtToken: string
  address: string
  user?: User
}
export interface SendPasswordlessLinkArgs {
  email: string
  redirectUri: string
  intent: string
}

export interface SendPasswordlessLinkReturn {
  status: boolean
}
export interface FriendListArgs {
  nickname?: string
  page?: Page
}

export interface FriendListReturn {
  page: Page
  friends: Array<Friend>
}
export interface GetFriendByAddressArgs {
  friendAddress: string
}

export interface GetFriendByAddressReturn {
  status: boolean
  friend: Friend
}
export interface SearchFriendsArgs {
  filterUsername: string
  page?: Page
}

export interface SearchFriendsReturn {
  friends: Array<Friend>
}
export interface AddFriendArgs {
  friendAddress: string
  optionalNickname?: string
}

export interface AddFriendReturn {
  status: boolean
  friend?: Friend
}
export interface UpdateFriendNicknameArgs {
  friendAddress: string
  nickname: string
}

export interface UpdateFriendNicknameReturn {
  status: boolean
  friend?: Friend
}
export interface RemoveFriendArgs {
  friendAddress: string
}

export interface RemoveFriendReturn {
  status: boolean
}
export interface ContractCallArgs {
  chainID: string
  contract: string
  inputExpr: string
  outputExpr: string
  args: Array<string>
}

export interface ContractCallReturn {
  returns: Array<string>
}
export interface DecodeContractCallArgs {
  callData: string
}

export interface DecodeContractCallReturn {
  call: ContractCall
}
export interface LookupContractCallSelectorsArgs {
  selectors: Array<string>
}

export interface LookupContractCallSelectorsReturn {
  signatures: Array<Array<string>>
}
export interface UserStorageFetchArgs {
  key: string
}

export interface UserStorageFetchReturn {
  object: any
}
export interface UserStorageSaveArgs {
  key: string
  object: any
}

export interface UserStorageSaveReturn {
  ok: boolean
}
export interface UserStorageDeleteArgs {
  key: string
}

export interface UserStorageDeleteReturn {
  ok: boolean
}
export interface UserStorageFetchAllArgs {
  keys?: Array<string>
}

export interface UserStorageFetchAllReturn {
  objects: { [key: string]: any }
}
export interface GetMoonpayLinkArgs {
  url: string
}

export interface GetMoonpayLinkReturn {
  signedUrl: string
}
export interface GetSardineClientTokenArgs {}

export interface GetSardineClientTokenReturn {
  token: string
}
export interface GetSardineNFTCheckoutTokenArgs {
  params: NftCheckoutParams
}

export interface GetSardineNFTCheckoutTokenReturn {
  resp: NftCheckout
}
export interface GetSardineNFTCheckoutOrderStatusArgs {
  orderId: string
}

export interface GetSardineNFTCheckoutOrderStatusReturn {
  resp: SardineOrder
}
export interface ResolveENSAddressArgs {
  ens: string
}

export interface ResolveENSAddressReturn {
  address: string
  ok: boolean
}
export interface IsValidSignatureArgs {
  chainId: string
  walletAddress: string
  digest: string
  signature: string
}

export interface IsValidSignatureReturn {
  isValid: boolean
}
export interface IsValidMessageSignatureArgs {
  chainId: string
  walletAddress: string
  message: string
  signature: string
}

export interface IsValidMessageSignatureReturn {
  isValid: boolean
}
export interface IsValidTypedDataSignatureArgs {
  chainId: string
  walletAddress: string
  typedData: any
  signature: string
}

export interface IsValidTypedDataSignatureReturn {
  isValid: boolean
}
export interface IsValidETHAuthProofArgs {
  chainId: string
  walletAddress: string
  ethAuthProofString: string
}

export interface IsValidETHAuthProofReturn {
  isValid: boolean
}
export interface GetCoinPricesArgs {
  tokens: Array<Token>
}

export interface GetCoinPricesReturn {
  tokenPrices: Array<TokenPrice>
}
export interface GetCollectiblePricesArgs {
  tokens: Array<Token>
}

export interface GetCollectiblePricesReturn {
  tokenPrices: Array<TokenPrice>
}
export interface GetExchangeRateArgs {
  toCurrency: string
}

export interface GetExchangeRateReturn {
  exchangeRate: ExchangeRate
}
export interface MemoryStoreArgs {
  key: string
  value: string
}

export interface MemoryStoreReturn {
  ok: boolean
}
export interface MemoryLoadArgs {
  key: string
}

export interface MemoryLoadReturn {
  value: string
}
export interface GetInviteInfoArgs {}

export interface GetInviteInfoReturn {
  inviteInfo: InviteInfo
}
export interface IsValidAccessCodeArgs {
  accessCode: string
}

export interface IsValidAccessCodeReturn {
  status: boolean
}
export interface InternalClaimAccessCodeArgs {
  address: string
  accessCode: string
}

export interface InternalClaimAccessCodeReturn {
  status: boolean
}
export interface BlockNumberAtTimeArgs {
  chainId: number
  timestamps: Array<number>
}

export interface BlockNumberAtTimeReturn {
  blocks: Array<number>
}
export interface PaperSessionSecretArgs {
  chainName: string
  contractAddress: string
  paramsJson: string
  contractType: string
}

export interface PaperSessionSecretReturn {
  secret: string
}
export interface PaperSessionSecret2Args {
  chainName: string
  contractAddress: string
  paramsJson: string
  abi: string
}

export interface PaperSessionSecret2Return {
  secret: string
}
export interface LinkWalletArgs {
  chainId: string
  walletAddress: string
  ethAuthProofString: string
  linkedWalletMessage: string
  linkedWalletSignature: string
}

export interface LinkWalletReturn {
  status: boolean
  linkedWalletAddress: string
}
export interface GetLinkedWalletsArgs {
  walletAddress: string
}

export interface GetLinkedWalletsReturn {
  linkedWallets: Array<string>
}
export interface RemoveLinkedWalletArgs {
  chainId: string
  walletAddress: string
  ethAuthProofString: string
  linkedWalletMessage: string
  linkedWalletSignature: string
}

export interface RemoveLinkedWalletReturn {
  status: boolean
}
export interface GenerateWaaSVerificationURLArgs {
  walletAddress: string
}

export interface GenerateWaaSVerificationURLReturn {
  nonce: string
  verificationURL: string
}
export interface ValidateWaaSVerificationNonceArgs {
  nonce: string
  signature: string
  sessionId: string
  chainId: string
}

export interface ValidateWaaSVerificationNonceReturn {
  walletAddress: string
}
export interface GetSwapQuotesArgs {
  userAddress: string
  currencyAddress: string
  currencyAmount: string
  chainId: number
  includeApprove: boolean
}

export interface GetSwapQuotesReturn {
  swapQuotes: Array<SwapQuote>
}
export interface AddCurrencyGroupArgs {
  currencyGroup: CurrencyGroup
}

export interface AddCurrencyGroupReturn {
  groupId: number
}
export interface UpdateCurrencyGroupArgs {
  currencyGroup: CurrencyGroup
}

export interface UpdateCurrencyGroupReturn {}
export interface ListCurrencyGroupsArgs {}

export interface ListCurrencyGroupsReturn {
  currencyGroups: Array<CurrencyGroup>
}
export interface DeleteCurrencyGroupArgs {
  groupId: number
}

export interface DeleteCurrencyGroupReturn {
  ok: boolean
}
export interface AddInventoryPaymentConfigArgs {
  config: InventoryPaymentConfig
}

export interface AddInventoryPaymentConfigReturn {
  configId: number
}
export interface GetInventoryPaymentConfigArgs {
  configId: number
}

export interface GetInventoryPaymentConfigReturn {
  config: InventoryPaymentConfig
}
export interface ListInventoryPaymentConfigsArgs {
  projectId: number
}

export interface ListInventoryPaymentConfigsReturn {
  configs: Array<InventoryPaymentConfig>
}
export interface UpdateInventoryPaymentConfigArgs {
  config: InventoryPaymentConfig
}

export interface UpdateInventoryPaymentConfigReturn {}
export interface DeleteInventoryPaymentConfigArgs {
  configId: number
}

export interface DeleteInventoryPaymentConfigReturn {
  ok: boolean
}
export interface RequestInventoryPaymentArgs {
  configId: number
  recipient: string
  chainId?: number
  tokenAddress?: string
}

export interface RequestInventoryPaymentReturn {
  payment: InventoryPaymentResponse
}

//
// Client
//
export class API implements API {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/API/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  ping = (headers?: object, signal?: AbortSignal): Promise<PingReturn> => {
    return this.fetch(this.url('Ping'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  version = (headers?: object, signal?: AbortSignal): Promise<VersionReturn> => {
    return this.fetch(this.url('Version'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            version: <Version>_data.version
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  runtimeStatus = (headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn> => {
    return this.fetch(this.url('RuntimeStatus'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <RuntimeStatus>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  clock = (headers?: object, signal?: AbortSignal): Promise<ClockReturn> => {
    return this.fetch(this.url('Clock'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            serverTime: <string>_data.serverTime
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSequenceContext = (headers?: object, signal?: AbortSignal): Promise<GetSequenceContextReturn> => {
    return this.fetch(this.url('GetSequenceContext'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            data: <SequenceContext>_data.data
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getAuthToken = (args: GetAuthTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetAuthTokenReturn> => {
    return this.fetch(this.url('GetAuthToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            jwtToken: <string>_data.jwtToken,
            address: <string>_data.address,
            user: <User>_data.user
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getAuthToken2 = (args: GetAuthToken2Args, headers?: object, signal?: AbortSignal): Promise<GetAuthToken2Return> => {
    return this.fetch(this.url('GetAuthToken2'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            jwtToken: <string>_data.jwtToken,
            address: <string>_data.address,
            user: <User>_data.user
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sendPasswordlessLink = (
    args: SendPasswordlessLinkArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SendPasswordlessLinkReturn> => {
    return this.fetch(this.url('SendPasswordlessLink'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  friendList = (args: FriendListArgs, headers?: object, signal?: AbortSignal): Promise<FriendListReturn> => {
    return this.fetch(this.url('FriendList'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            friends: <Array<Friend>>_data.friends
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getFriendByAddress = (
    args: GetFriendByAddressArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetFriendByAddressReturn> => {
    return this.fetch(this.url('GetFriendByAddress'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            friend: <Friend>_data.friend
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchFriends = (args: SearchFriendsArgs, headers?: object, signal?: AbortSignal): Promise<SearchFriendsReturn> => {
    return this.fetch(this.url('SearchFriends'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            friends: <Array<Friend>>_data.friends
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addFriend = (args: AddFriendArgs, headers?: object, signal?: AbortSignal): Promise<AddFriendReturn> => {
    return this.fetch(this.url('AddFriend'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            friend: <Friend>_data.friend
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateFriendNickname = (
    args: UpdateFriendNicknameArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateFriendNicknameReturn> => {
    return this.fetch(this.url('UpdateFriendNickname'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            friend: <Friend>_data.friend
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeFriend = (args: RemoveFriendArgs, headers?: object, signal?: AbortSignal): Promise<RemoveFriendReturn> => {
    return this.fetch(this.url('RemoveFriend'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  contractCall = (args: ContractCallArgs, headers?: object, signal?: AbortSignal): Promise<ContractCallReturn> => {
    return this.fetch(this.url('ContractCall'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            returns: <Array<string>>_data.returns
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  decodeContractCall = (
    args: DecodeContractCallArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DecodeContractCallReturn> => {
    return this.fetch(this.url('DecodeContractCall'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            call: <ContractCall>_data.call
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  lookupContractCallSelectors = (
    args: LookupContractCallSelectorsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<LookupContractCallSelectorsReturn> => {
    return this.fetch(this.url('LookupContractCallSelectors'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            signatures: <Array<Array<string>>>_data.signatures
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageFetch = (args: UserStorageFetchArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageFetchReturn> => {
    return this.fetch(this.url('UserStorageFetch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            object: <any>_data.object
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageSave = (args: UserStorageSaveArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageSaveReturn> => {
    return this.fetch(this.url('UserStorageSave'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageDelete = (args: UserStorageDeleteArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageDeleteReturn> => {
    return this.fetch(this.url('UserStorageDelete'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageFetchAll = (
    args: UserStorageFetchAllArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UserStorageFetchAllReturn> => {
    return this.fetch(this.url('UserStorageFetchAll'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            objects: <{ [key: string]: any }>_data.objects
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getMoonpayLink = (args: GetMoonpayLinkArgs, headers?: object, signal?: AbortSignal): Promise<GetMoonpayLinkReturn> => {
    return this.fetch(this.url('GetMoonpayLink'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            signedUrl: <string>_data.signedUrl
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSardineClientToken = (headers?: object, signal?: AbortSignal): Promise<GetSardineClientTokenReturn> => {
    return this.fetch(this.url('GetSardineClientToken'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <string>_data.token
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSardineNFTCheckoutToken = (
    args: GetSardineNFTCheckoutTokenArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutTokenReturn> => {
    return this.fetch(this.url('GetSardineNFTCheckoutToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            resp: <NftCheckout>_data.resp
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSardineNFTCheckoutOrderStatus = (
    args: GetSardineNFTCheckoutOrderStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutOrderStatusReturn> => {
    return this.fetch(this.url('GetSardineNFTCheckoutOrderStatus'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            resp: <SardineOrder>_data.resp
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  resolveENSAddress = (args: ResolveENSAddressArgs, headers?: object, signal?: AbortSignal): Promise<ResolveENSAddressReturn> => {
    return this.fetch(this.url('ResolveENSAddress'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            address: <string>_data.address,
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidSignature = (args: IsValidSignatureArgs, headers?: object, signal?: AbortSignal): Promise<IsValidSignatureReturn> => {
    return this.fetch(this.url('IsValidSignature'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidMessageSignature = (
    args: IsValidMessageSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidMessageSignatureReturn> => {
    return this.fetch(this.url('IsValidMessageSignature'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidTypedDataSignature = (
    args: IsValidTypedDataSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidTypedDataSignatureReturn> => {
    return this.fetch(this.url('IsValidTypedDataSignature'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidETHAuthProof = (
    args: IsValidETHAuthProofArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidETHAuthProofReturn> => {
    return this.fetch(this.url('IsValidETHAuthProof'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getCoinPrices = (args: GetCoinPricesArgs, headers?: object, signal?: AbortSignal): Promise<GetCoinPricesReturn> => {
    return this.fetch(this.url('GetCoinPrices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenPrices: <Array<TokenPrice>>_data.tokenPrices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getCollectiblePrices = (
    args: GetCollectiblePricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetCollectiblePricesReturn> => {
    return this.fetch(this.url('GetCollectiblePrices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenPrices: <Array<TokenPrice>>_data.tokenPrices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getExchangeRate = (args: GetExchangeRateArgs, headers?: object, signal?: AbortSignal): Promise<GetExchangeRateReturn> => {
    return this.fetch(this.url('GetExchangeRate'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            exchangeRate: <ExchangeRate>_data.exchangeRate
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  memoryStore = (args: MemoryStoreArgs, headers?: object, signal?: AbortSignal): Promise<MemoryStoreReturn> => {
    return this.fetch(this.url('MemoryStore'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  memoryLoad = (args: MemoryLoadArgs, headers?: object, signal?: AbortSignal): Promise<MemoryLoadReturn> => {
    return this.fetch(this.url('MemoryLoad'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            value: <string>_data.value
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getInviteInfo = (headers?: object, signal?: AbortSignal): Promise<GetInviteInfoReturn> => {
    return this.fetch(this.url('GetInviteInfo'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            inviteInfo: <InviteInfo>_data.inviteInfo
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidAccessCode = (args: IsValidAccessCodeArgs, headers?: object, signal?: AbortSignal): Promise<IsValidAccessCodeReturn> => {
    return this.fetch(this.url('IsValidAccessCode'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  internalClaimAccessCode = (
    args: InternalClaimAccessCodeArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<InternalClaimAccessCodeReturn> => {
    return this.fetch(this.url('InternalClaimAccessCode'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  blockNumberAtTime = (args: BlockNumberAtTimeArgs, headers?: object, signal?: AbortSignal): Promise<BlockNumberAtTimeReturn> => {
    return this.fetch(this.url('BlockNumberAtTime'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            blocks: <Array<number>>_data.blocks
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  paperSessionSecret = (
    args: PaperSessionSecretArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<PaperSessionSecretReturn> => {
    return this.fetch(this.url('PaperSessionSecret'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            secret: <string>_data.secret
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  paperSessionSecret2 = (
    args: PaperSessionSecret2Args,
    headers?: object,
    signal?: AbortSignal
  ): Promise<PaperSessionSecret2Return> => {
    return this.fetch(this.url('PaperSessionSecret2'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            secret: <string>_data.secret
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  linkWallet = (args: LinkWalletArgs, headers?: object, signal?: AbortSignal): Promise<LinkWalletReturn> => {
    return this.fetch(this.url('LinkWallet'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            linkedWalletAddress: <string>_data.linkedWalletAddress
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getLinkedWallets = (args: GetLinkedWalletsArgs, headers?: object, signal?: AbortSignal): Promise<GetLinkedWalletsReturn> => {
    return this.fetch(this.url('GetLinkedWallets'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            linkedWallets: <Array<string>>_data.linkedWallets
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeLinkedWallet = (
    args: RemoveLinkedWalletArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveLinkedWalletReturn> => {
    return this.fetch(this.url('RemoveLinkedWallet'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  generateWaaSVerificationURL = (
    args: GenerateWaaSVerificationURLArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GenerateWaaSVerificationURLReturn> => {
    return this.fetch(this.url('GenerateWaaSVerificationURL'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            nonce: <string>_data.nonce,
            verificationURL: <string>_data.verificationURL
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  validateWaaSVerificationNonce = (
    args: ValidateWaaSVerificationNonceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ValidateWaaSVerificationNonceReturn> => {
    return this.fetch(this.url('ValidateWaaSVerificationNonce'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            walletAddress: <string>_data.walletAddress
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapQuotes = (args: GetSwapQuotesArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapQuotesReturn> => {
    return this.fetch(this.url('GetSwapQuotes'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapQuotes: <Array<SwapQuote>>_data.swapQuotes
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addCurrencyGroup = (args: AddCurrencyGroupArgs, headers?: object, signal?: AbortSignal): Promise<AddCurrencyGroupReturn> => {
    return this.fetch(this.url('AddCurrencyGroup'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            groupId: <number>_data.groupId
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateCurrencyGroup = (
    args: UpdateCurrencyGroupArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateCurrencyGroupReturn> => {
    return this.fetch(this.url('UpdateCurrencyGroup'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listCurrencyGroups = (headers?: object, signal?: AbortSignal): Promise<ListCurrencyGroupsReturn> => {
    return this.fetch(this.url('ListCurrencyGroups'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            currencyGroups: <Array<CurrencyGroup>>_data.currencyGroups
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteCurrencyGroup = (
    args: DeleteCurrencyGroupArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteCurrencyGroupReturn> => {
    return this.fetch(this.url('DeleteCurrencyGroup'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addInventoryPaymentConfig = (
    args: AddInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddInventoryPaymentConfigReturn> => {
    return this.fetch(this.url('AddInventoryPaymentConfig'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            configId: <number>_data.configId
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getInventoryPaymentConfig = (
    args: GetInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetInventoryPaymentConfigReturn> => {
    return this.fetch(this.url('GetInventoryPaymentConfig'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            config: <InventoryPaymentConfig>_data.config
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listInventoryPaymentConfigs = (
    args: ListInventoryPaymentConfigsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListInventoryPaymentConfigsReturn> => {
    return this.fetch(this.url('ListInventoryPaymentConfigs'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            configs: <Array<InventoryPaymentConfig>>_data.configs
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateInventoryPaymentConfig = (
    args: UpdateInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateInventoryPaymentConfigReturn> => {
    return this.fetch(this.url('UpdateInventoryPaymentConfig'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteInventoryPaymentConfig = (
    args: DeleteInventoryPaymentConfigArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteInventoryPaymentConfigReturn> => {
    return this.fetch(this.url('DeleteInventoryPaymentConfig'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  requestInventoryPayment = (
    args: RequestInventoryPaymentArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RequestInventoryPaymentReturn> => {
    return this.fetch(this.url('RequestInventoryPayment'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            payment: <InventoryPaymentResponse>_data.payment
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}

const createHTTPRequest = (body: object = {}, headers: object = {}, signal: AbortSignal | null = null): object => {
  return {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(body || {}),
    signal
  }
}

const buildResponse = (res: Response): Promise<any> => {
  return res.text().then(text => {
    let data
    try {
      data = JSON.parse(text)
    } catch (error) {
      let message = ''
      if (error instanceof Error) {
        message = error.message
      }
      throw WebrpcBadResponseError.new({
        status: res.status,
        cause: `JSON.parse(): ${message}: response text: ${text}`
      })
    }
    if (!res.ok) {
      const code: number = typeof data.code === 'number' ? data.code : 0
      throw (webrpcErrorByCode[code] || WebrpcError).new(data)
    }
    return data
  })
}

//
// Errors
//

export class WebrpcError extends Error {
  name: string
  code: number
  message: string
  status: number
  cause?: string

  /** @deprecated Use message instead of msg. Deprecated in webrpc v0.11.0. */
  msg: string

  constructor(name: string, code: number, message: string, status: number, cause?: string) {
    super(message)
    this.name = name || 'WebrpcError'
    this.code = typeof code === 'number' ? code : 0
    this.message = message || `endpoint error ${this.code}`
    this.msg = this.message
    this.status = typeof status === 'number' ? status : 0
    this.cause = cause
    Object.setPrototypeOf(this, WebrpcError.prototype)
  }

  static new(payload: any): WebrpcError {
    return new this(payload.error, payload.code, payload.message || payload.msg, payload.status, payload.cause)
  }
}

// Webrpc errors

export class WebrpcEndpointError extends WebrpcError {
  constructor(
    name: string = 'WebrpcEndpoint',
    code: number = 0,
    message: string = 'endpoint error',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcEndpointError.prototype)
  }
}

export class WebrpcRequestFailedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcRequestFailed',
    code: number = -1,
    message: string = 'request failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcRequestFailedError.prototype)
  }
}

export class WebrpcBadRouteError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRoute',
    code: number = -2,
    message: string = 'bad route',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRouteError.prototype)
  }
}

export class WebrpcBadMethodError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadMethod',
    code: number = -3,
    message: string = 'bad method',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadMethodError.prototype)
  }
}

export class WebrpcBadRequestError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRequest',
    code: number = -4,
    message: string = 'bad request',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRequestError.prototype)
  }
}

export class WebrpcBadResponseError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadResponse',
    code: number = -5,
    message: string = 'bad response',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadResponseError.prototype)
  }
}

export class WebrpcServerPanicError extends WebrpcError {
  constructor(
    name: string = 'WebrpcServerPanic',
    code: number = -6,
    message: string = 'server panic',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcServerPanicError.prototype)
  }
}

export class WebrpcInternalErrorError extends WebrpcError {
  constructor(
    name: string = 'WebrpcInternalError',
    code: number = -7,
    message: string = 'internal error',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcInternalErrorError.prototype)
  }
}

export class WebrpcClientDisconnectedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcClientDisconnected',
    code: number = -8,
    message: string = 'client disconnected',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcClientDisconnectedError.prototype)
  }
}

export class WebrpcStreamLostError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamLost',
    code: number = -9,
    message: string = 'stream lost',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamLostError.prototype)
  }
}

export class WebrpcStreamFinishedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamFinished',
    code: number = -10,
    message: string = 'stream finished',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamFinishedError.prototype)
  }
}

// Schema errors

export class UnauthorizedError extends WebrpcError {
  constructor(
    name: string = 'Unauthorized',
    code: number = 1000,
    message: string = 'Unauthorized access',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnauthorizedError.prototype)
  }
}

export class PermissionDeniedError extends WebrpcError {
  constructor(
    name: string = 'PermissionDenied',
    code: number = 1001,
    message: string = 'Permission denied',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, PermissionDeniedError.prototype)
  }
}

export class SessionExpiredError extends WebrpcError {
  constructor(
    name: string = 'SessionExpired',
    code: number = 1002,
    message: string = 'Session expired',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, SessionExpiredError.prototype)
  }
}

export class AbortedError extends WebrpcError {
  constructor(
    name: string = 'Aborted',
    code: number = 1005,
    message: string = 'Request aborted',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AbortedError.prototype)
  }
}

export class GeoblockedError extends WebrpcError {
  constructor(
    name: string = 'Geoblocked',
    code: number = 1006,
    message: string = 'Geoblocked region',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, GeoblockedError.prototype)
  }
}

export class InvalidArgumentError extends WebrpcError {
  constructor(
    name: string = 'InvalidArgument',
    code: number = 2000,
    message: string = 'Invalid argument',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidArgumentError.prototype)
  }
}

export class UnavailableError extends WebrpcError {
  constructor(
    name: string = 'Unavailable',
    code: number = 2002,
    message: string = 'Unavailable resource',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnavailableError.prototype)
  }
}

export class QueryFailedError extends WebrpcError {
  constructor(
    name: string = 'QueryFailed',
    code: number = 2003,
    message: string = 'Query failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QueryFailedError.prototype)
  }
}

export class NotFoundError extends WebrpcError {
  constructor(
    name: string = 'NotFound',
    code: number = 3000,
    message: string = 'Resource not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}

export enum errors {
  WebrpcEndpoint = 'WebrpcEndpoint',
  WebrpcRequestFailed = 'WebrpcRequestFailed',
  WebrpcBadRoute = 'WebrpcBadRoute',
  WebrpcBadMethod = 'WebrpcBadMethod',
  WebrpcBadRequest = 'WebrpcBadRequest',
  WebrpcBadResponse = 'WebrpcBadResponse',
  WebrpcServerPanic = 'WebrpcServerPanic',
  WebrpcInternalError = 'WebrpcInternalError',
  WebrpcClientDisconnected = 'WebrpcClientDisconnected',
  WebrpcStreamLost = 'WebrpcStreamLost',
  WebrpcStreamFinished = 'WebrpcStreamFinished',
  Unauthorized = 'Unauthorized',
  PermissionDenied = 'PermissionDenied',
  SessionExpired = 'SessionExpired',
  Aborted = 'Aborted',
  Geoblocked = 'Geoblocked',
  InvalidArgument = 'InvalidArgument',
  Unavailable = 'Unavailable',
  QueryFailed = 'QueryFailed',
  NotFound = 'NotFound'
}

const webrpcErrorByCode: { [code: number]: any } = {
  [0]: WebrpcEndpointError,
  [-1]: WebrpcRequestFailedError,
  [-2]: WebrpcBadRouteError,
  [-3]: WebrpcBadMethodError,
  [-4]: WebrpcBadRequestError,
  [-5]: WebrpcBadResponseError,
  [-6]: WebrpcServerPanicError,
  [-7]: WebrpcInternalErrorError,
  [-8]: WebrpcClientDisconnectedError,
  [-9]: WebrpcStreamLostError,
  [-10]: WebrpcStreamFinishedError,
  [1000]: UnauthorizedError,
  [1001]: PermissionDeniedError,
  [1002]: SessionExpiredError,
  [1005]: AbortedError,
  [1006]: GeoblockedError,
  [2000]: InvalidArgumentError,
  [2002]: UnavailableError,
  [2003]: QueryFailedError,
  [3000]: NotFoundError
}

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>
